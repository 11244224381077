body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(red_spiral.png);
  background-size:100% ;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

#under {
  height: 2em;
  width: 75%;
  text-align: center;
  font-size: 2em;
  color: red;
  font-weight: bold;
  border: black;
  border-style: solid;
  border-width: 5px;
  font-family: 'Times New Roman', Times, serif;
  background-size: 200px 200px;
  background-image: linear-gradient(45deg, yellow 25%, transparent 25%, transparent 50%, yellow 50%, yellow 75%, transparent 75%, transparent);
  background-color: black;
  background-repeat: repeat;
  animation: scrollBackground 7s linear infinite;
}

@keyframes scrollBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200px 0;
  }
}

#coming-soon {
  width: 55%;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
  padding: 20px;
  background-color: aliceblue;
  border-color: black;
  border-style: solid;
  border-width: 5px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(8, 8, 8);
  font-size: 2em;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}

#quote {
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
  
  padding-top: .5em;
  margin-top: 2em;
  padding-left: 1em;
  padding-right: 1em;
  background-color: aliceblue;
  border-color: black;
  border-style: solid;
  border-radius: 20px;
  width: 75%;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-size: 2em;
}
.quote-div {
  text-align: center;
}
.drk-btn {
  font-family: 'Times New Roman', Times, serif;
  width: 20%;
  color: #fff;
  background-color: #4b5b6b95;
  border-color: #343a40;
  text-decoration: none;
  border-radius: 13px;
  font-size: 1.5em;
}

.drk-btn:hover {
  color: #fff;
  background-color: #1e2124;
  border-color: #1d2124;
}

.btn-position {
  position: absolute;
  width: 70%;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.game-btn {
  margin-top: .5em;
  position: absolute;
  width: 20%;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.game {
  position: absolute;
  margin-bottom: 2em;
  top: 2%;
  left: 15%;
}

.start-btn {
  margin-top: .5em;
  position: absolute;
  width: 20%;
  top: 75%;
  left: 75%;
  transform: translate(-50%, -50%);
}

#gameCanvas {
margin: auto;
  border: 1px solid black;
  background-color: #eee;
  position: absolute;               
}

.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #333;
  padding: 10px;
}

.navbar a {
  color: white;
  text-decoration: none;
  padding: 8px 16px;
}

.navbar a:hover {
  background-color: #ddd;
  color: black;
}


@media screen and (max-width: 768px) {

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: url(red_spiral.png);
    background-size:fill ;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
  }

  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  #coming-soon {
    width: 75%;
    height: 10%;
    padding: .5em;
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.2em;
    text-align: center;
    background-color: aliceblue;
    border-color: black;
    border-style: solid;
    border-width: 5px;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(8, 8, 8);
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  #quote {
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
    padding-bottom: .2em;
    padding-top: .5em;
    margin-top: 2em;
    padding-left: 1em;
    padding-right: 1em;
    background-color: aliceblue;
    border-color: black;
    border-style: solid;
    border-radius: 20px;
    width: 75%;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    font-size: 1em;
  }
  .quote-div {
    text-align: center;
  }
  .drk-btn {
    font-family: 'Times New Roman', Times, serif;
    width: 75%;
    color: #fff;
    background-color: #4b5b6b95;
    border-color: #343a40;
    text-decoration: none;
    border-radius: 13px;
    font-size: 1.5em;
  }
  
  .drk-btn:hover {
    color: #fff;
    background-color: #1e2124;
    border-color: #1d2124;
  }
  
  .btn-position {
    position: absolute;
    width: 70%;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
/* 
  

  #gameCanvas {
    margin: auto;
    border: 1px solid black;
    background-color: #eee;
    position: absolute;               
    }

    .game {
      position: absolute;
      margin-bottom: 2em;
      top: 35%;
      left: 0%;
    } */

    
  

}